import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const banner = {
    title: "クッキー同意バナー",
    tagline: "使い方や設定も簡単で、数分でホームページに掲出できます。"
}

export const features = {
    image: (<StaticImage title='クッキー同意バナー' src={"../../static/images/feature/cookie-banner/featureImage.png"} width={530} quality={100} alt="Cookie Banner Feature Image"  />),
    contents: [
        {
            title: "クッキーバナーソリューション",
            paragraph: <p>Secure Privacyは、EUクッキー法およびGDPRクッキー同意要件を遵守するためのクッキー同意管理ツールとウェブサイトバナーを提供します。Secure Privacyが提供する先進的なクッキーバナーソリューションを使えば、美しいクッキーバナーを生成し、同意を文書化して記録し、安心を得ることができます。</p>
        },
    ]
}

